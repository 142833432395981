import * as React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import format from 'date-fns/format'
import styles from './blog-post.module.scss'

import {
  transform as toBlogPost,
  ContentfulTemplateBlogPost,
  BlogPost,
} from '../data/blog-post'

import { SEO } from '../components/seo'
import { PostCard } from '../components/card'
import { PostHeader, PostFooter, PostProgress } from '../components/section'
import { useDynamicTracking } from '../hooks'

interface Props {
  data: {
    contentfulPost: ContentfulTemplateBlogPost
    contentfulNextPost: ContentfulTemplateBlogPost
    allContentfulTemplateBlogPost: {
      edges: { node: ContentfulTemplateBlogPost }[]
    }
  }
}

export default class BlogPostPage extends React.Component<Props, {}> {
  public render() {
    const {
      contentfulPost,
      contentfulNextPost,
      allContentfulTemplateBlogPost,
    } = this.props.data
    const [post, nextPost, recentPosts] = [
      toBlogPost(contentfulPost),
      toBlogPost(contentfulNextPost),
      allContentfulTemplateBlogPost.edges.map(({ node }) => toBlogPost(node)),
    ]

    return (
      <>
        <SEO
          image={post.hero && post.hero.sizes.src}
          title={post.title}
          description={post.description}
          canonical={post.canonical}
          blogPost={{
            author: post.author.name,
            datePublished: format(post.publishDate, 'MMMM d, yyyy'),
          }}
        />
        <article>
          {!post.hero ? null : (
            <GatsbyImage className={styles.hero} {...post.hero} />
          )}
          <PostHeader
            title={post.title}
            publishDate={post.publishDate}
            authorName={post.author.name}
            estimatedLength={post.readingTime + ' min read'}
          />
          <div className="container container--grid">
            <div className="container__row">
              <div
                className="wysiwyg child-my-0"
                dangerouslySetInnerHTML={{ __html: post.body }}
              />
            </div>
          </div>
          {post.closer ? (
            <PostFooter>
              <div dangerouslySetInnerHTML={{ __html: post.closer }} />
            </PostFooter>
          ) : null}
        </article>
        <PostProgress
          postTitle={post.title}
          shareLink={post.slug}
          nextPostTitle={nextPost.title}
          nextPostLink={nextPost.slug}
        />
        <RecentStories posts={recentPosts} />
      </>
    )
  }
}

function RecentStories({ posts }: { posts: BlogPost[] }) {
  const [onTrack] = useDynamicTracking({
    action: 'Click Recent Story',
    category: 'Blog Post',
  })
  return (
    <aside className="related-stories bg-n90">
      <div className="container container--grid">
        <div className="container__row">
          <h2 className="related-stories__heading type-sp-xxl">
            Recent Stories
          </h2>
          <div className="related-stories__grid">
            {posts.map(post => {
              const d = format(post.publishDate, 'MMMM d, yyyy')
              return (
                <div className="related-stories__item" key={post.slug}>
                  <PostCard
                    title={post.title}
                    subtitle={`${d} | ${post.author.name}`}
                    slug={post.slug}
                    image={post.hero}
                    onClick={() => onTrack({ label: post.title })}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </aside>
  )
}

export const query = graphql`
  query BlogPostPage($slug: String!, $next: String!) {
    contentfulPost: contentfulTemplateBlogPost(slug: { eq: $slug }) {
      ...BlogPostFields
    }
    contentfulNextPost: contentfulTemplateBlogPost(slug: { eq: $next }) {
      ...BlogPostFields
    }
    allContentfulTemplateBlogPost(
      limit: 4
      filter: { slug: { ne: $slug } }
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          ...BlogPostFields
        }
      }
    }
  }
`
