import {
  CFResponsiveAsset,
  ResponsiveImage,
  MarkdownRemark,
} from './types/gatsby'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import {
  transform as toPerson,
  ContentfulTypePerson,
  Person,
  fallback,
} from './person'

export type ContentfulTemplateBlogPost = {
  /** UUID format */
  id: string
  /** Slug */
  slug: string
  /** Post title (Short text) */
  title: string
  /** Publication date */
  publishDate: string
  /** Canonical URL */
  canonical: string
  /** Remarked Description/Excerpt (Long text) */
  description: {
    /** Contentful long text but plain/no format */
    description: string
  }
  /** Feature image */
  heroImage: null | CFResponsiveAsset
  /** Post attribution, Person type */
  author: ContentfulTypePerson
  /** Remarked Body Content (Long text) */
  body: {
    /** gatsby-transformer-remark */
    childMarkdownRemark: MarkdownRemark
  }
  /** Remarked Body Closing Paragraph (Long text) */
  closer: {
    /** gatsby-transformer-remark */
    childMarkdownRemark: MarkdownRemark
  }
}

export type BlogPost = {
  /** UUID format */
  id: string
  /** Slug */
  slug: string
  /** Post title */
  title: string
  /** Canonical URL */
  canonical: string
  /** Body content HTML, converted from Markdown in GraphQL query */
  body: string
  /** Excerpt HTML, converted from Markdown in GraphQL query */
  description: string
  /** Featured image */
  hero: null | ResponsiveImage
  /** Closing paragraph HTML, converted from Markdown in GraphQL query */
  closer: null | string
  /** Publication date object */
  publishDate: Date
  /** Calculated reading time */
  readingTime: number
  /** Author */
  author: Person
}

export const fragment = graphql`
  fragment BlogPostFields on ContentfulTemplateBlogPost {
    id
    title
    slug
    publishDate
    canonical
    heroImage {
      ...ResponsiveAssetFields
    }
    author {
      ...PersonFields
    }
    body {
      childMarkdownRemark {
        timeToRead
        html
      }
    }
    description {
      description
    }
    closer {
      childMarkdownRemark {
        html
      }
    }
  }
`

const toHeroImage = (img: CFResponsiveAsset) =>
  !img
    ? null
    : {
        sizes: img.sizes,
        alt: img.title || img.description,
      }

export const transform = (node: ContentfulTemplateBlogPost): BlogPost => ({
  id: node.id,
  slug: `/blog/${node.slug}`,
  title: get(node, 'title', ''),
  canonical: get(node, 'canonical', ''),
  hero: toHeroImage(node.heroImage),
  body: get(node, 'body.childMarkdownRemark.html', ''),
  description: get(node, 'description.description', ''),
  closer: get(node, 'closer.childMarkdownRemark.html', ''),
  publishDate: node.publishDate ? new Date(node.publishDate) : new Date(0),
  readingTime: get(node, 'body.childMarkdownRemark.timeToRead', 1),
  author: node.author ? toPerson(node.author) : fallback(),
})
