import { graphql } from 'gatsby'
import { ContentfulAsset } from './types/contentful'
import { Image } from './types/gatsby'
import { optional } from '../utils/optional'
// FIXME: TS error for image import
import imageFallback from '../images/fallback.png'

export type ContentfulTypePerson = {
  /** UUID */
  id: string
  /** Display name */
  name: string
  /** Name of role on team (e.g. co-founder) */
  title: string
  /** Probably "Designer Fund" */
  company: string
  /** Short biography (No more than a paragraph) */
  shortBio: {
    shortBio: string
  }
  /** Headshot asset */
  image: ContentfulAsset
  /** Linked in (URL not username) */
  linkedIn: null | string
  /** Medium (URL not username) */
  medium: null | string
  /** Twitter (URL not username) */
  twitter: null | string
  /** Facebook (URL not username) */
  facebook: null | string
  /** Github (URL not username) */
  github: null | string
}

type SocialLink = {
  /** Type */
  type: string
  /** URL */
  link: string
}

export type Person = {
  /** Their name */
  name: string
  /** Their bio */
  bio: string
  /** Their role or job title */
  title: string
  /** Their headshot */
  photo: Image
  /** Do @ me */
  socials: SocialLink[]
}

export const fragment = graphql`
  fragment PersonFields on ContentfulTypePerson {
    id
    name
    title
    company
    shortBio {
      shortBio
    }
    image {
      id
      description
      file {
        url
      }
    }
    linkedIn
    medium
    facebook
    twitter
    github
  }
`

const teamSocials = (node: ContentfulTypePerson): SocialLink[] => {
  const socials = []
  if (node.linkedIn) socials.push({ type: 'linked-in', link: node.linkedIn })
  if (node.twitter) socials.push({ type: 'twitter', link: node.twitter })
  if (node.medium) socials.push({ type: 'medium', link: node.medium })
  if (node.facebook) socials.push({ type: 'facebook', link: node.facebook })
  if (node.github) socials.push({ type: 'github', link: node.github })
  return socials
}

export const transform = (node: ContentfulTypePerson): Person => ({
  name: node.name,
  bio: node.shortBio.shortBio,
  title: node.title,
  socials: teamSocials(node),
  photo: {
    src: optional(node, 'image', 'file', 'url') || imageFallback,
    alt: optional(node, 'image', 'description') || 'None provided',
  },
})

export const fallback = () => ({
  name: 'Designer Fund',
  bio:
    'We invest in leaders and empower them to improve the world with design.',
  title: 'Team',
  socials: [{ type: 'twitter', link: '//twitter.com/designerfund' }],
  photo: {
    src: imageFallback,
    alt: 'Fallback image',
  },
})
